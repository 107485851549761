<template>
  <div class="orderList-view view pa24">
    <el-row :gutter="24">
    </el-row>
    <!-- :spanMethod="objectSpanMethod" -->
    <commonTable :tableData="tableData"
                 @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange"
                 :currentPage="pageNum"
                 :loading="loading"
                 :total="total">
      <template v-slot:table>
        <el-table-column prop="typeName" align="center" label="类型"></el-table-column>
        <el-table-column prop="title" align="center" label="页面名称"></el-table-column>
        <el-table-column prop="createTime" width="100" align="center" label="创建时间">
          <!-- <template slot-scope="scope">
            {{scope.row.createTime}}
          </template> -->
        </el-table-column>
        <el-table-column prop="handle" align="center" label="标识">
        </el-table-column>
        <el-table-column align="center" label="标识二维码" width="150">
          <template slot-scope="scope">
            <img class="codeImage" :src="scope.row.codeImg"/>
          </template>
        </el-table-column>
        <el-table-column align="center" label="验证码">
          <template slot-scope="scope">
            <span>{{scope.row.verificationCode}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="是否回读">
          <template slot-scope="scope">
            <span>{{scope.row.isRead ? '是' : '不需要回读'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="scanNum" align="center" label="可扫码次数">
        </el-table-column>
        <el-table-column prop="useScanNum" align="center" label="剩余扫码次数">
          <template slot-scope="scope">
            <span>{{scope.row.scanNum - scope.row.useScanNum > 0? (scope.row.scanNum - scope.row.useScanNum) : -1}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="lotNumber" align="center" label="批次号">
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import commonTable from "@/components/common/commonTable";
import { getHandleList } from '@/api/handleCustomerManage.js'
const QR = require('@/utils/weapp-qrcode.js')
export default {
  name: "customerHandleList",
  components: {
    commonTable,
  },
  data() {
    return {
      pickerValue: "", //下单时间
      loading:false,
      total: 0, //总条数
      currentPage: 1, //当前页数
      start:'',
      end:'',
      tableData: [],
      pageNum: 1,
      pageSize: 10,
      handleCustomerId: null,
      handle: '',
      lotNumber: undefined,
      handleLink: undefined, // 复制链接
    };
  },
  created() {
    if(this.$route.query) {
      this.handleCustomerId =this.$route.query.id
      this.selectOrdersInfoPcAsync();
    }
  },
  watch:{
  },
  filters: {
  },
  methods: {
    /**@method 获取页面列表 */
    async selectOrdersInfoPcAsync() {
      let params = {
        handleCustomerId: this.handleCustomerId,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      const result = await getHandleList(params)
      this.tableData = result.data.list
      this.tableData = result.data.list.map(item => {
        if (item.handle) {
          let codeUrl = 'https://fwapi.lfsnms.com/h?h=' + item.handle
          item.codeImg = QR.drawImg(codeUrl, {
            typeNumber: 4,
            errorCorrectLevel: 'M',
            size: 196 // 创建尺寸，用于下载
          })
        }
        return item
      })
      this.total = result.data.total
      console.log(result)
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.selectOrdersInfoPcAsync();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.selectOrdersInfoPcAsync();
    }
  },
};
</script>

<style scoped lang='scss'>
.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
}
.codeImage{ // 展示尺寸 100
  width: 100px;
  height: 100px;
}
</style>
