import httpUtil from "@/utils/httpUtil";

/***添加客户 */
export const addCustomer = async params => httpUtil.post("/handleCustomer", params);

/**修改客户信息**/
export const updateCustomer = async params => httpUtil.put("/handleCustomer", params);

/***客户列表 */
export const customerList = async params => httpUtil.get("/handleCustomer", params);

export const getHandleList = async params => httpUtil.get("/handleCustomer/getHandleList", params);

/***删除客户 */
export function deleteCustomer(id) {
    return httpUtil.delete(`/handleCustomer/delete/${id}`)
}
